import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import styles from "../styles/post.module.scss";

const LegalPage = () => (
  <Layout>
    <SEO title="Legal Info law.photography" />
    <div className={styles.postContainer}>
      <article className={styles.post}>
        <h2>Ownership</h2>
        <p>
          The content on this website is{" "}
          <a
            target="creative_commons"
            href="https://creativecommons.org/licenses/by/2.0/"
          >
            Creative Commons Attribution 2.0 Generic (CC BY 2.0)
          </a>
          , which means you can use all the content on it, also commercially or
          adapt it any way you like.
        </p>
        <p>
          The site is run by us, Judith and Jonathan, if you have any concerns
          or would like to get in touch with us, shoot us a mail at
          hello@gegenwind.dk.
        </p>
        <h2>Data Protection</h2>
        <p>
          We're not collection your data beyond what Google Analytics collects,
          should you allow cookies. We look at which pages get read more than
          others, that's all. Should you contribute to this website via a git /
          cms service, we'll know your username on that site.
        </p>
      </article>
    </div>
  </Layout>
);

export default LegalPage;
